@tailwind base;
@tailwind components;
@tailwind utilities;

.work-order-service-name .ql-editor {
  @apply text-lg;
  padding: 0;
}
.ms-Panel-main {
  background-color: #efefef;
}

.ms-Dialog-main {
  border-radius: 20px;
}

a,
body a,
a:hover,
body a:hover {
  color: inherit;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 221.2 83.2% 53.3%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 221.2 83.2% 53.3%;
    --radius: 0.3rem;

    --chart-1: 221.2 83.2% 53.3%;
    --chart-2: 212 95% 68%;
    --chart-3: 216 92% 60%;
    --chart-4: 210 98% 78%;
    --chart-5: 212 97% 87%;
    --chart-6: 173 58% 39%;
    --chart-7: 12 76% 61%;
    --chart-8: 197 37% 24%;
    --chart-9: 43 74% 66%;
    --chart-10: 27 87% 67%;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 217.2 91.2% 59.8%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 224.3 76.3% 48%;

    --chart-1: 221.2 83.2% 53.3%;
    --chart-2: 212 95% 68%;
    --chart-3: 216 92% 60%;
    --chart-4: 210 98% 78%;
    --chart-5: 212 97% 87%;
    --chart-6: 220 70% 50%;
    --chart-7: 160 60% 45%;
    --chart-8: 30 80% 55%;
    --chart-9: 280 65% 60%;
    --chart-10: 340 75% 55%;
  }

  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }
}
.work-order-item-description .ql-editor {
  padding: 0;
}

.dumb-scroll > * {
  display: block !important;
}

.bg-custom-gradiant {
  mask-image: radial-gradient(rgb(0, 0, 0), transparent 90%);
}

.bg-custom-radial {
  background-image: radial-gradient(
    circle,
    rgb(42, 43, 48) 1px,
    rgb(42, 43, 48) 1px,
    transparent 1px,
    transparent 100%
  );
  background-size: 8px 8px;
  opacity: 0.3;
}
