.loading-icon {
  animation: rotation 2s infinite; /* IE 10+, Fx 29+ */
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
